import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { LooseAutocomplete } from "../../pojo/Util";
import { ButtonClassSuffix } from "../../standalone/s25.button.component";
import { NgbPlacementArray } from "../s25-popover/popover.component";

@TypeManagerDecorator("s25-ng-image-preview")
@Component({
    selector: "s25-ng-image-preview",
    template: `
        <div class="image-preview-wrapper">
            <s25-popover
                [modelBean]="{ popoverTemplate: imagePreview }"
                [openTrigger]="'click'"
                [closeTrigger]="'click'"
                [placement]="popoverPlacement"
            >
                <button class="aw-button aw-button--{{ buttonStyle }}">{{ buttonText }}</button>
            </s25-popover>
            @if (showCopyLink) {
                <s25-ng-image-copy-link [itemId]="imageId" [buttonStyle]="'text'"></s25-ng-image-copy-link>
            }
        </div>

        <ng-template #imagePreview>
            <s25-ng-image [model]="{ imageId }"></s25-ng-image>
        </ng-template>
    `,
    styles: `
        .image-preview-wrapper {
            display: flex;
            gap: 5px;
            align-items: center;
        }

        ::ng-deep .c-textButton {
            font-weight: 600;
        }

        ::ng-deep .popover {
            max-width: 500px;
            z-index: 1051; /* when popover opened in modal, need a unit higher than modal z-index */
        }

        @media screen and (width < 530px) {
            .image-preview-wrapper {
                flex-direction: row-reverse;
            }
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
})
export class S25ImagePreviewComponent {
    @Input({ required: true }) imageId: number;
    @Input() buttonText?: string = "View";
    @Input() buttonStyle?: LooseAutocomplete<ButtonClassSuffix> = "outline";
    @Input() showCopyLink?: boolean;
    @Input() popoverPlacement?: NgbPlacementArray = "auto";
}
